
import { defineComponent, ref } from 'vue';
import TelaPadraoCrud from '@/core/components/TelaPadraoCrud.vue';
import { useCrudBase } from '@/core/composables/CrudBase';
import PessoaModal from '@/views/Cadastros/Pessoas/PessoaModal.vue';
import { ECustomRenderRow } from '@/core/models/AntDesign/IColumn';
import { IOrdenacao } from '@/core/models/Consulta/IOrdenacao';
import ServicoColaborador from '@/servicos/Cadastros/Pessoas/ServicoColaborador';
import { ETipoRelacaoPessoa } from '@/models/Enumeradores/Cadastros/Pessoas/ETipoRelacaoPessoa';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';

export default defineComponent({
  name: 'Colaborador',
  components: {
    TelaPadraoCrud,
    PessoaModal,
  },
  setup() {
    const {
      crudBase, preencherEstrutura, exibirModal, sincronizaTelaCrud,
    } = useCrudBase();

    const refCrudColaborador = ref<InstanceType<typeof TelaPadraoCrud>>();

    const servicoColaborador = new ServicoColaborador();

    crudBase.estrutura = preencherEstrutura();
    crudBase.estrutura.apresentarEmpresas = true;
    crudBase.estrutura.verificarCadastroCompartilhado = true;
    crudBase.estrutura.botaoPrincipalAcao = 'Novo';
    crudBase.estrutura.mensagemExclusao = 'Você confirma a exclusão do colaborador';
    crudBase.estrutura.colunasGrade = [
      {
        title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 0, visible: false, ordering: true,
      },
      {
        title: 'Nome', dataIndex: 'nome', key: 'Nome', position: 2, ellipsis: true, visible: true, ordering: true, fixed: 'left', align: 'left', customRenderRow: ECustomRenderRow.LinkAcao,
      },
      {
        title: 'Departamento', dataIndex: 'departamento', key: 'Departamento', position: 3, ellipsis: true, visible: true, ordering: true,
      },
      {
        title: 'Função', dataIndex: 'funcao', key: 'Funcao', position: 4, ellipsis: true, visible: true, ordering: true,
      },
      {
        title: 'Status', dataIndex: 'ativo', key: 'ativo', position: 5, visible: true, width: 100, align: 'left', customRenderRow: ECustomRenderRow.TagAtivo,
      },
      {
        title: 'Ações', key: 'acoes', position: 6, visible: true, fixed: 'right', width: 100, align: 'center', customRenderRow: ECustomRenderRow.IconeAcoes,
      },
    ];
    crudBase.estrutura.ordenacaoPadraoGrade.push({ identificador: 'CodigoPessoa', ordem: 'DESC' } as IOrdenacao);

    async function sincronizarRegistro(acao: EPermissaoDados, codigoRegistro: number) {
      if (refCrudColaborador.value !== undefined) {
        await refCrudColaborador.value.sincronizarRegistro(acao, codigoRegistro);
      }
    }

    return {
      crudBase,
      servicoColaborador,
      ETipoRelacaoPessoa,
      exibirModal,
      sincronizaTelaCrud,
      refCrudColaborador,
      sincronizarRegistro,
    };
  },
});
